import * as dat from "dat.gui";

class Gui {
    constructor() {
        console.log("Gui constructor")
        this.gui = new dat.GUI();
  this.fov = 60;

    }
}

export default Gui;


