export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const APP = "/landing";
export const PLANNER = "/planner";
export const GALLERY = "/gallery";
export const BUILDER = "/builder";
export const STUDIO = "/studio";
export const ACCOUNT_MANAGEMENT = "/account_management";
